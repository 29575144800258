import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate  ? (
            <div className="heading-date">
              {props.fromDate }
            </div>
          ) : (
            <div></div>
          )}
          {props.acquireDate ? (
            <div className="heading-date">
              {props.acquireDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
        {props.validation ? <div className="resume-heading-vacidation">
          <div className="validate-number"> 
            <span className="validation-header">VALIDATION NUMBER: </span>
            <span>  {props.validation ? props.validation: ""}</span>
          </div>
          <div className="validate-at"> 

          <span className="validation-header">VALIDATE AT: </span>

          <a href="https://aws.amazon.com/verification"> https://aws.amazon.com/verification </a> 
          </div>
        </div>
        : ""
        }

      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Education", logoSrc: "fa fa-graduation-cap" },
    { label: "Certificate", logoSrc: "fa fa-certificate"},
    { label: "Experience", logoSrc: "fa fa-briefcase" },
    { label: "Programming Skills", logoSrc: "fa fa-file-code-o" },
    { label: "Projects", logoSrc: "fa fa-archive" },
    { label: "Interests", logoSrc: "fa fa-comment-o" },
  ];

  //here we have
  const courseworkDetails = [
    { coursework: "Linear Algebra", ratingPercentage: 85 },
    { coursework: "Data Structure & Algorithm", ratingPercentage: 85 },
    { coursework: "Agile Software Development Practices", ratingPercentage: 85 },
    { coursework: "Software Construction & Design", ratingPercentage: 85 },
    { coursework: "Concurrency for Software Development", ratingPercentage: 85 },
    { coursework: "Object-Oriented Programming", ratingPercentage: 85 },
    { coursework: "Distributed System", ratingPercentage: 85 },
    { coursework: "System Programming", ratingPercentage: 85 },
    { coursework: "OS and Networking", ratingPercentage: 85 },
    { coursework: "Advanced Data Models", ratingPercentage: 85 },


  ]
  const programmingSkillsDetails = [
    { skill: "Java", ratingPercentage: 85 },
    { skill: "Python", ratingPercentage: 80 },
    { skill: "C", ratingPercentage: 80 },
    { skill: "SQL", ratingPercentage: 80 },
    { skill: "MongoDB", ratingPercentage: 80 },
    { skill: "AWS RDS", ratingPercentage: 80 },
    { skill: "AWS S3", ratingPercentage: 85 },
    { skill: "React Native", ratingPercentage: 85 },
    { skill: "Flutter", ratingPercentage: 89 },
    { skill: "HTML", ratingPercentage: 80 },
    { skill: "CSS", ratingPercentage: 80 },
  ];

  const projectsDetails = [
    {
      title: "Personal Portfolio Website",
      duration: { fromDate: "June 2023" },
      description:
        "A personal portfolio website to showcase all my working experience and projects",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
    {
      title: "GUI crypto conversion software",
      duration: { fromDate: "March 2023" },
      description:
        "I retrieve real-time data from the CoinMarketCap API. Key features of the software include the ability to convert one crypto to another, send a report to the user's email, send a report to a Reddit thread, and a caching system (which allows the user to choose whether they want to retrieve real-time data from the API or data from a local database)",
      subHeading:
        "Technologies Used:  Java, JavaFX, Gradle",
    },
  ];

  const resumeDetails = [
    /* EDUCATION*/
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"University of Sydney"}
        subHeading={"BACHELOR OF COMPUTING (DAYELL SCHOLAR)"}
        fromDate={"2020-2023"}
      />
        {courseworkDetails.map((coursework, index) => (
        <div className="coursework-parent" key={index}>
          <div className="heading-bullet2"></div>
          <span className="coursework-name">{coursework.coursework}</span>

        </div>
      ))}

    </div>,
    /* CERTIFICATE*/
    <div className="resume-screen-container" key="certificate">
    <ResumeHeading
      heading={"AWS CLOUD PRACTITIONER"}
      acquireDate={"JUNE 2023"}
      validation={"9WPNRNG2XFR4QT59"}
      imageSrc={'../../../src/assets/Resume/aws-certified-cloud-practitioner.png'}
      urlTag={"https://www.credly.com/badges/e5c7e8fd-b372-46c8-9bb2-ab32784b2f20/public_url"}
    />


  </div>,
    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"UNIVERSITY OF SYDNEY (Capstone Unit)"}
          subHeading={"Software Engineer"}
          fromDate={"Aug 2022 - Nov 2022"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
          - Working in a team of 5 developers under agile methodology to develop a mobile e-commerce application.
          </span>
          <br />
          <span className="resume-description-text">
            - Responsible for designing, setting up, and managing the database for the project, which includes creating an ER diagram, setting up cloud database and integrating the database with both the server and client applications..{" "}
          </span>
          <br />
          <span className="resume-description-text">
            - Responsible for implementing various features of the mobile application, such as the forget password, product search and cart.
          </span>
          <br />
        </div>
      </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>

        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
        />
      ))}
    </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Running"
        description="I simply love running. It helps me relax and be with myself. Often, I usually find software solutions when I run."
      />
      <ResumeHeading
        heading="Tennis"
        description="If you're seeking a tennis partner, feel free to reach out to me.




        "
      />
      <ResumeHeading
        heading="Food"
        description="I have an insatiable desire to continually explore and experience new dining establishments."
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
         <i className={bullet.logoSrc +" bullet-logo"}></i>

        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
