import React from "react";


import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import Footer from "../../PortfolioContainer/footer/Footer";
import "./ContactMe.css";

export default function ContactMe(props) {


  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="col">
          
          <div className="icons">

          <a href="https://www.facebook.com/kittibhumi.jaggabatara">
                <i className="fa fa-facebook-square"></i>
              </a>
          <a href="https://www.linkedin.com/in/kittibhumi-j-354902268/">
                <i className="fa fa-linkedin-square"></i>
              </a>
          </div>
          <div className="email">
          <span >Email: topkittibhumi@gmail.com</span>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
