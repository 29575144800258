import React from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";

import "./AboutMe.css";

export default function AboutMe(props) {
//      "I am currently going through the application process for a Temporary Graduate visa (485) in Australia. The government recently extended the duration of this visa to 4 years, and I expect to receive the visa before July 2023. I kindly request that any potential companies interested in scheduling an onsite interview with me choose a date from 1 July 2023. Meanwhile, I am actively working on improving my coding skill. I have a strong commitment to making the most of any opportunity that comes my way, please give me a chance :)",


  const SCREEN_CONSTSANTS = {
    heading1: "Who I am?",
    description1:
      " I am a recent graduate from the University of Sydney, where I obtained a Bachelor of Computing degree. I have a solid understanding of data structures and algorithms, design patterns and their practical implementation. My primary programming language is Java, however I am open to exploring opportunities in other programming languages and frameworks. I am a diligent and hardworking individual.  I have a strong commitment to making the most of any opportunity that comes my way, please give me a chance :)",
    heading2: "",
    description2:
      "",
      highlights: {
      bullets: [
        "Proficient in Java",
        "Strong data structure & algorithm knowledge",
        "Solid understanding of OOP and Design Patterns",
        "Strong SQL knowledge",
        "Have experiece working with AWS services",
      ],
      heading: "Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <div className="about-me-heading1">
              {SCREEN_CONSTSANTS.heading1}
            </div>
            <div className="about-me-description1">
              {SCREEN_CONSTSANTS.description1}
            </div>
            <div className="about-me-heading2">
              {SCREEN_CONSTSANTS.heading2}
            </div>
            <div className="about-me-description2">
              {SCREEN_CONSTSANTS.description2}
            </div>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>

          </div>
        </div>
        <div className="about-me-profile2"></div>
      </div>
    </div>
    
  );
}
