import React from "react";
import "./Profile.css";
export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
      <div className="profile-picture">
            <div className="profile-picture-background"></div>
          </div>
        <div className="profile-details">

          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hello, I'm{" "}
              <span className="highlighted-text"> Kittibhumi Jaggabatara</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                {" "}

                    "Software Engineer"

              </h1>
              <span className="profile-role-tagline">
              I am a recent graduate from the University of Sydney, currently seeking a software engineer opportunity.
              </span>
            </span>
          </div>

          <div className="profile-options">
          <div className="colz">
            <div className="colz-icon">
              <a href="https://www.facebook.com/kittibhumi.jaggabatara">
                <i className="fa fa-facebook-square"></i>
              </a>

              <a href="https://www.linkedin.com/in/kittibhumi-j-354902268/">
                <i className="fa fa-linkedin-square"></i>
              </a>
            </div>
          </div>

            <a href="https://s3.ap-southeast-2.amazonaws.com/www.topkittibhumi.com/kittibhumi_cv.pdf" download="kittibhumi_cv.pdf">
              <button className="btn highlighted-btn">Download Resume</button>
            </a>
          </div>

        </div>

      </div>
    </div>
  );
}
